import { Stack, Typography } from "@mui/material";
import CodeRain from "../components/display/effects/CodeRain";
import NavBar from "../components/navigation/NavBar";
const Home = (props) => {
  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <Stack
        mt={25}
        display={"flex"}
        direction={"row"}
        justifyContent={"center"}
      >
        <Stack>
          <Typography variant="h1">
            I love coding beautiful and clever things.
          </Typography>
          <Typography variant="h2">(I hope we can collaborate)</Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default Home;
