import { Stack } from "@mui/material"
import FilterChip from "./FilterChip"
const ChipsContainer = (props) => {
    return (
        <Stack
            ml={props.marginLeft || 2}
            spacing={props.spacing || 1}
            direction={props.direct || "row"}
            alignItems="center"
            sx={{flexGrow:1}}
        >
            {props.chipValues.map(value=>(
            <FilterChip
                key={value}
                label={value}
                color={props.color}
                onDelete={props.onDelete}
            />
            ))}
        </Stack>
    )
}

export default ChipsContainer