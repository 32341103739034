import { Stack } from "@mui/material";
import { useEffect, useState } from "react"
import FadingCharacter from "./FadingCharacter";
import useStopWatch from "../../../hooks/useStopWatch";
const CodeRain = (props) => {
    const STARTING_CHARS = 20;
    const STARTING_COLS = 56;
    const ADD_RAINDROP_PERCENT = .02;
    const FADE_AMMOUNT = .05;
    const MS_BETWEEN_UPDATES = 300;

    const newChar = () => {
        const firstValue = 12448;
        const possibleValues = 16 * 6;
        const isNumberPercent = .1;
        if (Math.random() <= isNumberPercent) {
            return Math.floor(Math.random()*10);
        } else {
            return String.fromCharCode(firstValue + Math.floor(possibleValues * Math.random()));
        }
    }
    const initGrid = () => {
        const grid = []
        for (let i=0; i<STARTING_COLS; i++){
            grid.push([]);
            for (let j=0; j<STARTING_CHARS; j++){
                grid[i].push({char:"\u30A0", opacity:0})
            }
        }
        return grid
    }
    const initRaindropPostions=()=>{
        const arr=[];
        for (let i=0; i<STARTING_COLS; i++){
            arr.push([]);
        }
        return arr;
    }
    const [charGrid, setCharGrid] = useState(initGrid());
    const [visibleChars, setVisibleChars] = useState([]);
    const [raindropPositions, setRaindropPositions] = useState(initRaindropPostions());

    const updateStopwatch = useStopWatch({
        startingTime: 0,
        delta: MS_BETWEEN_UPDATES,
        running: true
    })

    useEffect(()=>{
        const updatedRaindropPositions = raindropPositions;
        const updatedGrid = charGrid;
        let updatedVisibleChars = visibleChars;

        const newVisibleChars = [];
        for (let rCol=0; rCol<updatedRaindropPositions.length; rCol++){
            if (Math.random() <= ADD_RAINDROP_PERCENT) {
                updatedRaindropPositions[rCol].push(-1)
            }
            for(let rPos = 0; rPos < updatedRaindropPositions[rCol].length; rPos++) {
                updatedRaindropPositions[rCol][rPos]++
                const rRow = updatedRaindropPositions[rCol][rPos];
                if (rRow < STARTING_CHARS) {
                    newVisibleChars.push(updatedGrid[rCol][rRow]);
                }
            }
            updatedRaindropPositions[rCol] = updatedRaindropPositions[rCol].filter(val=>val<STARTING_CHARS);
        }
        updatedVisibleChars.forEach(char=>char.opacity -= FADE_AMMOUNT);
        updatedVisibleChars = updatedVisibleChars.filter(char => char.opacity > 0);
        newVisibleChars.forEach(char=>{char.char=newChar();char.opacity=1});
        updatedVisibleChars = [...newVisibleChars, ...updatedVisibleChars];
        
        setRaindropPositions(()=>updatedRaindropPositions);
        setVisibleChars(()=>updatedVisibleChars)
        setCharGrid(()=>updatedGrid)
        // eslint-disable-next-line
    }, [updateStopwatch.time]);

    return (
        <>
            {props.codeRain &&
            <Stack direction="row" sx={{position: "fixed", zIndex: -1}}>
                {charGrid.map(col=>{return (
                    <Stack direction="column" key={Math.random()} display={"flex"} alignItems={"center"}>
                        {col.map(char=>{return (
                            <FadingCharacter char={char.char} opacity={char.opacity} key={Math.random()}/>
                        )})}
                    </Stack>
                )})}
            </Stack>}
        </>
    )
}

export default CodeRain