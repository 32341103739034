import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import NewBadge from "./NewBadge";
import ChipsContainer from "../controls/filter/ChipsContainer";
const AccordionGroup = (props) => {
  const [expanded, setExpanded] = useState(props.open || false);
  const handleChange = (isExpanded, title) => {
    setExpanded(() => (isExpanded ? title : false));
  };
  return (
    <>
      {props.children.map((article) => {
        return (
          <Accordion
            key={article.title}
            expanded={expanded === article.title}
            onChange={(e, isExpanded) =>
              handleChange(isExpanded, article.title)
            }
            sx={{ border: 2, borderColor: "primary.main" }}
          >
            <AccordionSummary
              id={`${article.title}-header`}
              aria-controls={`${article.title}-content`}
              expandIcon={<ExpandCircleDownIcon htmlColor="#3f48cc" />}
            >
              <Stack>
                <NewBadge
                  postedDate={article.postedDate}
                  xOffset={5}
                  yOffset={-2}
                />
                <Typography variant="h4" component="div">
                  {article.title}
                </Typography>
                {article.subtitle && (
                  <Typography component="div">{article.subtitle}</Typography>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Divider color="#3f48cc" />
              {article.innerHeader && (
                <Typography mt={2} variant="body2" component="div">
                  {article.innerHeader}
                </Typography>
              )}
              {article.tags && (
                <Box mt={2}>
                  <ChipsContainer
                    chipValues={article.tags}
                    color={"secondary"}
                  />
                </Box>
              )}
              {article.body && Array.isArray(article.body) ? (
                article.body.map((para) => {
                  return (
                    <Typography mt={3} key={Math.random()} component="div">
                      {para}
                    </Typography>
                  );
                })
              ) : (
                <Typography mt={3} component="div">
                  {article.body}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default AccordionGroup;
