import { useEffect, useState } from "react";

function useStopWatch(props){
    const [running, setRunning] = useState(props.running);
    const [time, setTime] = useState(props.startingTime);
    const [delta, setDelta] = useState(props.delta);
    const [result, setResult] = useState();

    useEffect(() => {
        let interval;
        if (running) {
          interval = setInterval(() => {
            setTime((prevTime) => prevTime + delta);
            if (props.execute){
              setResult(props.execute());
            }
          }, 10);
        } else if (!running) {
          clearInterval(interval);
        }
        return () => clearInterval(interval);
      }, [running,delta,props]);
      return {running, setRunning, time, setTime, delta, setDelta, result}
}

export default useStopWatch;