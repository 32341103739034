import AccordionGroup from "../../display/AccordionGroup";
import BandAccordion from "./BandAccordion";
import NewTabLink from "../../navigation/NewTabLink";
const MusicAccordion = () => {
  const musicObjs = [
    {
      title: "Bands/Artists:",
      innerHeader: "(Last Level, I promise)",
      body: [<BandAccordion />],
    },
    {
      title: "Specific Songs:",
      subtitle: "(not necessarily the artist's catalog)",
      body: [
        <NewTabLink href="https://www.youtube.com/watch?v=v_B3qkp4nO4" text="Shotgun - George Ezra"/>,
        <NewTabLink href="https://www.youtube.com/watch?v=SS0LyfJCsxo" text="Good Gone Girl - MIKA"/>,
        <NewTabLink href="https://www.youtube.com/watch?v=2zNSgSzhBfM" text="Can't Hold Us - Macklemore & Ryan Lewis feat. Ray Dalton"/>,
        <NewTabLink href="https://www.youtube.com/watch?v=BWXWivwhi14" text="Don't Leave Me (Ne Me Quitte Pas) - Regina Spektor"/>
      ],
    },
    {
      title: "Number One with a Bullet (even if it is several years old):",
      body: [
        <NewTabLink href="https://www.youtube.com/watch?v=pRpeEdMmmQ0" text="Waka Waka (This Time for Africa) - Shakira"/>
      ],
    },
    {
      title: "Pandora Station:",
      body: [
        <NewTabLink href="https://www.pandora.com/station/play/2408282798282419145" text="Fun. Radio"/>
      ],
    },
  ];
  return <AccordionGroup>{musicObjs}</AccordionGroup>;
};

export default MusicAccordion;
