import { createTheme } from "@mui/material";

export default createTheme({
  typography: {
    fontFamily: ['Rajdhani', 'monospace'].join(","),
    caption: {
      fontFamily:"'Inconsolata', monospace"
    }
  },
  palette: {
    primary: {
      main: "#3f48cc"
    },
    secondary: {
      main: "#9398e2"
    },
    background: {
      paper: "#000000",
      default: "#222222",
    },
    text: {
      primary: "#ffffff",
    },
    success: {
        main: "#53d273"
    },
    error: {
        main: "#e94f64"
    }
  },
});
