import { Typography, Button, Avatar, Card } from "@mui/material";
import { Stack } from "@mui/system";
import Settings from "./Settings";
import mine from "../../resources/display/mine.png";
import NavBar from "../../../../../components/navigation/NavBar";
import CodeRain from "../../../../../components/display/effects/CodeRain";

function Home(props) {
  return (
    <>
    <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
    <Stack direction="column" width={"100%"} alignItems="center" spacing={1}>
      <Typography variant="h1">
        TimeSweeper
      </Typography>
      <Settings
        difficultyParameters={props.difficultyParameters}
        difficulty={props.difficulty}
        setDifficulty={props.setDifficulty}
        setView={props.setView}
        updateBestTime={props.updateBestTime}
        bestTime={props.bestTime}
        isTimed={props.isTimed}
        updateIsTimed={props.updateIsTimed}
        winRecords={props.winRecords}
      />
      <Card elevation={10} sx={{ width: "min-content" }}>
        <Button onClick={() => props.setView("gamePlay")}>
          <Stack direction={"row"} spacing={1}>
            <Avatar src={mine} />
            <Stack direction="column" justifyContent={"center"}>
              <Typography>{`Play ${props.difficulty}`}</Typography>
            </Stack>
            <Avatar src={mine} />
          </Stack>
        </Button>
      </Card>
    </Stack>
    </>
  );
}

export default Home;
