import ProjectCard from "./ProjectCard"
import { Grid } from "@mui/material"
const CardContainer = (props) => {
    return (
        <Grid p={2} container spacing={2}>
            {props.projectsData.map(project=>{return (
                <Grid item key={project.header}>
                    <ProjectCard 
                        header={project.header}
                        body={project.body}
                        bodyVariant={project.bodyVariant}
                        buttonText={project.buttonText}
                        buttonType={project.buttonType}
                        href={project.href}
                        onClick={project.onClick}
                    />
                </Grid>
            )})}
        </Grid>
    )
}

export default CardContainer