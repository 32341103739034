import React from "react";
import { Button, IconButton, Paper, Stack, Typography } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NavBar from "../components/navigation/NavBar";
import CodeRain from "../components/display/effects/CodeRain";
const Contact = (props) => {
  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <Paper>
        <Stack mt={5} display={"flex"} alignItems={"center"}>
          <Typography p={1}>Send me an Email!</Typography>
          <Button
            variant="contained"
            color="primary"
            target="_top"
            rel="noopener noreferrer"
            href={`mailto:robsim37@gmail.com`}
          >
            <Typography>RobSIM37@gmail.com</Typography>
          </Button>
          <Typography p={1}>Or visit one of my social accounts...</Typography>
          <Stack direction={"row"}>
            <IconButton
              size="large"
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/RobSIM37"
            >
              <GitHubIcon />
            </IconButton>
            <IconButton
              size="large"
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/rob-lewis-a56a5b56/"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default Contact;
