import { Link } from "@mui/material";
import NewBadge from "../display/NewBadge";
const NewTabLink = (props) => {
  return (
    <>
      <NewBadge postedDate={props.postedDate} />
      <Link
        target="_blank"
        rel="noopener"
        href={props.href}
        color="text.primary"
      >
        {props.text}
      </Link>
    </>
  );
};

export default NewTabLink;
