import { Grid, Stack, Typography } from "@mui/material";
import NavBar from "../components/navigation/NavBar";
import CodeRain from "../components/display/effects/CodeRain";
import ImageCircle from "../components/display/ImageCircle";
import MainAccordion from "../components/content/about/MainAccordion";
import me from "../resources/robLewis.png";
const About = (props) => {
  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <Grid container p={2} spacing={2}>
        <Grid
          item
          xs={12}
          sm={3}
          flex
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack flex justifyContent={"center"} alignItems={"center"}>
            <ImageCircle
              src={me}
              outerRadius={400}
              innerRadius={380}
              innerBorder="false"
            />
            <Typography variant="h4">My Face</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          flex
          justifyContent={"center"}
          alignItems={"center"}
        >
          <MainAccordion open={props.open} />
        </Grid>
      </Grid>
    </>
  );
};

export default About;
