const isPointOnBoard = (x, y, board) => {
    return x >= 0 && x < board[0].length && y >= 0 && y < board.length
}

const pointIsNotOrigin = (x,y) => {
    return !(x === 0 && y ===0)
}

const pullFromArray = (arr) => {
    const rndIndex = randomIndex(arr);
    const pulledCell = arr.splice(rndIndex,1)[0]
    return pulledCell;
}

const randomIndex = (arr) => {
    return Math.floor(Math.random() * arr.length);
}

const distanceBetweenPoints = (a,b) => {
    return Math.sqrt((b.x - a.x)**2 + (b.y - a.y)**2)
}

const countNeighbors = (board) => {
    board.forEach(row=>row.forEach(cell=>{
        let neighborCount = 0;
        const boardWidth = board[0].length;
        const boardHeight = board.length;
        for (let dX = -1; dX<2; dX++){
            for(let dY = -1; dY<2; dY++){
                if (!(dX === 0 && dY === 0)
                    && cell.x + dX >= 0 && cell.x + dX < boardWidth
                    && cell.y + dY >= 0 && cell.y + dY < boardHeight
                    && board[cell.y + dY][cell.x+dX].isMine) {
                        neighborCount++
                    }
            }
        }
        cell.neighbors = neighborCount;
    }));
    return board;
}

const populateBoard = (board, firstClick, mineCount, isTimed) => {
    const emptyCells = [];
    board.forEach(row=>row.forEach(cell=>{
        if (distanceBetweenPoints(firstClick,cell) >=2) {
            emptyCells.push(cell)
        }
    }));
    for (let m=0; m<mineCount; m++){
        const newMine = pullFromArray(emptyCells);
        newMine.isMine = true
        if (isTimed && m===0) {
            newMine.isTimebomb = true;
        }
    }
    board = countNeighbors(board);
    return board;
}

export const initBoard = (width, height, firstClick=null, mineCount=0, isTimed=true) => {

    const board = [];
    for (let y=0; y < height; y++) {
        const row = [];
        for (let x=0; x < width; x++) {
            row.push({
                hidden: true,
                marked: false,
                isMine: false,
                isTimebomb: false,
                neighbors: 0,
                x,
                y
            })
        }
        board.push(row);
    }
    if (firstClick) {
        return populateBoard(board, firstClick, mineCount, isTimed)
    } else {
        return [...board]
    }
}

export const gridToDisplay = (grid) => {
    return grid.map(row => {
        return row.map(cell => {
            if (cell.hidden && cell.marked && cell.isTimebomb) {
                return {image: "greenFlag", x: cell.x, y: cell.y}
            }
            if (cell.hidden && cell.marked) {
                return {image: "redFlag", x: cell.x, y: cell.y}
            }
            if (cell.hidden) {
                return {image: "hidden", x: cell.x, y: cell.y}
            }
            if (cell.isTimebomb && cell.marked) {
                return {image: "clearedTimeBomb", x: cell.x, y: cell.y}
            }
            if (cell.isTimebomb) {
                return {image: "timeBomb", x: cell.x, y: cell.y}
            }
            if (cell.isMine && cell.marked) {
                return {image: "clearedMine", x: cell.x, y: cell.y}
            }
            if (cell.isMine) {
                return {image: "mine", x: cell.x, y: cell.y}
            }
            if (cell.marked) {
                return {image: "incorrectFlag", x: cell.x, y: cell.y}
            }
            return {image: `${cell.neighbors}`, x: cell.x, y: cell.y}
        })
    })
}

export const updateBoard = (board,x,y) => {
    board[y][x].hidden = false
    if (board[y][x].neighbors === 0) {
       for (let dX = -1; dX < 2; dX++) {
            for (let dY = -1; dY < 2; dY++) {
                if (isPointOnBoard(x+dX,y+dY,board) && board[y+dY][x+dX].hidden){
                        updateBoard(board, x+dX,y+dY)
                    }
            }
        } 
    }
    return board;
}

export const autoReveal = (board, x, y) => {
    let flagCount = 0;
    const cellsToReveal = [];
    for (let dX = -1; dX < 2; dX++) {
        for (let dY = -1; dY < 2; dY++) {
            if (isPointOnBoard(x+dX,y+dY,board) && pointIsNotOrigin(dX,dY)) {
                    if (board[y+dY][x+dX].marked){
                        flagCount++
                    }
                    if (board[y+dY][x+dX].hidden && !board[y+dY][x+dX].marked){
                        cellsToReveal.push({x:x+dX, y:y+dY})
                    }
                } 
        }
    }
    if (flagCount === board[y][x].neighbors) {
        cellsToReveal.forEach(cell=> board = updateBoard(board, cell.x, cell.y))
    }
    return board;
}

export const isLoss = (board) => {
    let isLoss = false;
    board.forEach(row=>row.forEach(cell=>{
        if (cell.isMine && !cell.hidden){
            isLoss = true;
        }
    }));
    return isLoss
}

export const isWin = (board) => {
    let count = 0;
    board.forEach(row=>row.forEach(cell=>{
        if (cell.isMine || !cell.hidden){
            count++
        }
    }));
    return count === board.length * board[0].length;
}

export const showAll = (board) => {
    board.forEach(row=>row.forEach(cell=>{
        cell.hidden = false
    }));
    return board;
}

export const flagAll = (board) => {
    board.forEach(row=>row.forEach(cell=>{
        if (cell.isMine) {
            cell.marked = true
        }
    }));
    return board
}

export const calculateWinPercentage = (winRecords, difficulty, isTimed) => {
    const timedKey = isTimed ? "timed" : "untimed";
    const recordsArr = winRecords[difficulty][timedKey];
    const recordsPercentObj = recordsArr.reduce((ack,cur)=>cur ?
                                {win: ack.win+1, total: ack.total+1} :
                                {win: ack.win, total: ack.total+1},
                                {win:0, total:0});
    if (recordsPercentObj.total === 0){
        return "0%"
    } else {
        return `${Math.round(((recordsPercentObj.win/recordsPercentObj.total)+Number.EPSILON)*100)}%`
    }
}

export const winsAndLosses = (winRecords, difficulty, isTimed) => {
    const timedKey = isTimed ? "timed" : "untimed";
    const recordsArr = winRecords[difficulty][timedKey];
    let winCount = 0;
    let lossCount = 0;
    recordsArr.forEach(rec=>rec ? winCount++ : lossCount++);
    return `${winCount}W - ${lossCount}L`
}
export const detectMine = (board, radar) => {
    const unflaggedMines = [];
    board.forEach(row=>{
        row.forEach(cell=>{
            if (cell.isMine && !cell.marked && !cell.isTimebomb){
                unflaggedMines.push(cell);
            }
        })
    })
    unflaggedMines.forEach(cell=>{if (Math.random() < 0.15 * radar) cell.marked = true})
    unflaggedMines[Math.floor(Math.random()*unflaggedMines.length)].marked = true;
    return board;
}