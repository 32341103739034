import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Paper
} from "@mui/material";
import ninja from "../../resources/ninja.png";
import ninjaBlink from "../../resources/ninjaBlink.png";
import smileNinja from "../../resources/smileNinja.png";
import smileNinjaBlink from "../../resources/smileNinjaBlink.png";
import frownNinja from "../../resources/frownNinja.png";
import frownNinjaBlink from "../../resources/frownNinjaBlink.png";
import laughNinja from "../../resources/laughNinja.png";
import laughNinjaBlink from "../../resources/laughNinjaBlink.png";
import { useNavigate } from "react-router";
import ButtonArray from "./ButtonArray";
import RainToggleButton from "../controls/RainToggleButton";

const NavBar = (props) => {
  const faces= [
    [ninja, smileNinja, laughNinja, frownNinja],
    [ninjaBlink, smileNinjaBlink, laughNinjaBlink, frownNinjaBlink]
  ]
  const navigate = useNavigate();
  const navPageSettings = [
    {page:"Home", type:"outlined", onClick: ()=>{navigate("/home")}},
    {page:"Projects", type:"outlined", onClick: ()=>{navigate("/projects")}},
    {page:"About", type:"outlined", onClick: ()=>{navigate("/about")}},
    {page:"Blog", type:"outlined", onClick: ()=>{navigate("/blog")}},
    {page:"Contact", type:"contained", onClick: ()=>{navigate("/contact")}},
  ]
  return (
    <AppBar position="static">
      <Paper sx={{ border: 3, borderColor: "primary.main" }}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              marginRight: 2,
            }}
            alt="Ninja icon"
            src={faces[props.faceBlinking][props.faceIndex]}
            onMouseEnter={props.toggleBlink}
            onMouseLeave={props.toggleBlink}
            onClick={props.advanceFace}
          />
          <Typography variant="h3" component="div" sx={{ flexGrow: 1, typography: { sm: 'h3', xs: 'h5' } }}>
            Rob Lewis
          </Typography>
          <ButtonArray buttonSettings={navPageSettings}/>
          { props.setCodeRain && <RainToggleButton codeRain={props.codeRain} onClick={props.setCodeRain} />}
        </Toolbar>
      </Paper>
    </AppBar>
  );
};

export default NavBar;
