import NavBar from "../components/navigation/NavBar";
import CodeRain from "../components/display/effects/CodeRain";
import { Box, Paper, Stack, Typography } from "@mui/material";
import frownNinja from "../resources/frownNinja.png";
const NotFound = (props) => {
  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <Stack
        mt={25}
        display={"flex"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          mr={3}
          component="img"
          sx={{
            height: 300,
            width: 300,
          }}
          alt="Ninja icon"
          src={frownNinja}
        />
        <Stack>
          <Box width={"fit-content"}>
            <Paper>
              <Typography p={2} variant="h1">
                404
              </Typography>
            </Paper>
          </Box>
          <Typography variant="h1">That URL... doesn't compute... </Typography>
          <Typography variant="h2">
            (The NavBar should get you back to where you want to be.)
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default NotFound;
