import AccordionGroup from "../../display/AccordionGroup";
import MediaAccordion from "./MediaAccordion";
const MainAccordion = (props) => {
  const articleObjs = [
    {
      title: "Professional History",
      subtitle: "a.k.a 'How to EXCEL without even trying'",
      body: [
        "Most recently, I spent a year at Perficent, Inc. I was a graduate of a company sponsored bootcamp, where I was instructed in the basics of JavaScript, HTML, CSS, Node JS, Express servers, SQL Databases. In the specialization portion of the bootcamp, I learned the fundamentals of Java and the Spring Framework. I dove deeper into creating a backend and persisting data in a SQL Database. While working at Perficient, after being selected for a position in the custom product development business unit, I was exposed to (and learned quite a lot about) React, Material UI, and C#. I worked both on a new project, seeing it built from scratch, and a long running project, seeing the challenges that accumulate on such a project.",
        "While this was my first job specifically in the tech industry, I utilized my programming talents in every job I have had. This mostly manifested in the use of Excel and programming in the VBA programming language under the hood. I have created tools for various industries, including healthcare, manufacturing, logistics, transportation, the armed forces, and finance. I was ecstatic when I leveraged my Excel skills in the tech industry by creating a tool that quickly converted customer specifications presented on an Excel spreadsheet into a valid JSON object for use in a web application. This made it trivial for the customer to express their wishes and have those wishes built into the application.",
      ],
    },
    {
      title: "Personal History",
      subtitle: "(with programming, obviously)",
      body: [
        "I have loved programming for decades. Everyone my age had an interest in the Atari, NES, and Sega video games. However, not everyone shared my interest in PROGRAMMING! This was sparked by my exposure to the Apple IIe in a middle school computer class. I went into the semester knowing nothing about programming and left understanding Apple Basic significantly better than the average 12 year old. All the basic concepts of looping and branching code were cemented into my brain. By the end of the year, while the other students were writing batch files that rendered crude drawing to the screen, I had created an interactive drawing program that allowed the user to move the cursor across the screen, dragging a trail of color behind them.",
        "In high school, I moved on to the TI-83 programable graphing calculator. Here I learned quite a bit more about the power (and fun) of collaboration, as I worked with several other fellow students to create a good number of programs. We built a suite of casino games that shared a common “bank account”. I was particularly pleased with my version of Black Jack, as the issue of Aces being conditionally valued at either 1 or 11 is a non-trivial issue to solve.",
        "After schooling, I dabbled in Visual Basic and C#, creating several (now long lost) personal projects. My favorite application from these years was my SUDOKU SOLVER. It had a very cleaver approach to solving ANY puzzle. Eventually I came to understand that most office computers come installed with Excel and that opens up a whole world of ad hoc programming. I often joke that I think in terms of Excel, and that's not far from the truth, as I find it to be an excellent platform to organize my thoughts, make quick but complex calculations, and generate proof of concepts.",
        "All in all, what I have learned from my years of programming experience is that all languages are unique, but they all share core concepts that (once mastered) aid in the understanding of new concepts!",
      ],
    },
    {
      title: "Things I Like",
      subtitle: "Media, hobbies, etc",
      innerHeader:
        "(You like accordions in your accordions? I've got accordions in my Accordions!)",
      body: <MediaAccordion />,
    },
  ];
  return <AccordionGroup open={props.open}>{articleObjs}</AccordionGroup>;
};

export default MainAccordion;
