import AccordionGroup from "../../display/AccordionGroup";
import MusicAccordion from "./MusicAccordion";
import NewTabLink from "../../navigation/NewTabLink";
const MediaAccordion = () => {
  const mediaObjs = [
    {
      title: "Books:",
      body: [
        <NewTabLink
          href="https://schellgames.com/art-of-game-design/"
          text="The Art of Game Design: A Book of Lenses by Jesse Schell"
        />,
        <NewTabLink
          href="https://georgerrmartin.com/grrm_book/knight-of-the-seven-kingdoms/"
          text="A Knight of the Seven Kingdoms by George R.R. Martin"
        />,
        <NewTabLink
          href="https://www.deankoontz.com/book-series/odd-thomas"
          text="Odd Thomas by Dean Koontz"
        />,
      ],
    },
    {
      title: "Movies:",
      body: [
        <NewTabLink
          href="https://www.imdb.com/title/tt0128442/"
          text="Rounders"
        />,
        <NewTabLink
          href="https://www.imdb.com/title/tt0133093/"
          text="The Matrix"
        />,
        <NewTabLink
          href="https://www.imdb.com/title/tt0362227/"
          text="The Terminal"
        />,
      ],
    },
    {
      title: "TV Shows:",
      body: [
        <NewTabLink
          href="https://www.imdb.com/title/tt0083399/"
          text="Cheers"
        />,
        <NewTabLink
          href="https://www.imdb.com/title/tt0106145/"
          text="Star Trek: DS9"
        />,
        <NewTabLink
          href="https://www.imdb.com/title/tt0433309/"
          text="Numb3rs"
        />,
      ],
    },
    {
      title: "Music:",
      innerHeader: "(Recursive Accordions!? Woo Hoo!!)",
      body: <MusicAccordion />,
    },
    {
      title: "YouTube Videos:",
      body: [
        <NewTabLink
          postedDate="2023-04-06"
          href="https://www.youtube.com/watch?v=l4w6808wJcU&t=927s"
          text="DOES YOUR FLAG FAIL? Grey Grades The State Flags!"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=Pwe-pA6TaZk"
          text="Where the Hell is Matt? 2012"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          text="Mastering the Art of Timeless Referential Comedy"
        />,
      ],
    },
    {
      title: "Games:",
      body: [
        <NewTabLink
          href="https://boardgamegeek.com/boardgame/178900/codenames"
          text="Codenames"
        />,
        <NewTabLink
          href="https://boardgamegeek.com/boardgame/36218/dominion"
          text="Dominion"
        />,
        <NewTabLink
          href="https://boardgamegeek.com/boardgame/159503/captain-dead"
          text="The Captain Is Dead"
        />,
      ],
    },
  ];
  return <AccordionGroup>{mediaObjs}</AccordionGroup>;
};

export default MediaAccordion;
