import { Button, Avatar, Radio, Typography, Card, Checkbox } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import grid from "../../resources/display/grid.png";
import mine from "../../resources/display/mine.png";
import timeBomb from "../../resources/display/timeBomb.png";
import { timeDisplayString } from "../../utils/time";
import { capitalizeFirstLetter } from "../../utils/strings";
import { calculateWinPercentage } from "../../utils/gamePlay";

function Settings(props) {
  const [difficultySelected, setDifficultySelected] = useState(props.difficulty);
  const difficulties = ["easy", "intermediate", "hard"];
  const radioChangeEventHandler = (e) => {
    setDifficultySelected(e.target.value);
    props.setDifficulty(e.target.value);
  };

  const timedCheckboxChangeHandler = (e) => {
    props.updateIsTimed(e.target.value, e.target.checked);
    radioChangeEventHandler(e);
  };

  return (
    <Stack direction="row" spacing={3} width={"100%"} justifyContent="center">
      {difficulties.map((difficulty, i) => {
        return (
          <Card key={i} elevation={10} sx={{border:2, borderColor:"primary.main"}}>
            <Stack direction="column" alignItems={"center"} width={250}>
              <Typography width={100} textAlign="center">
                {capitalizeFirstLetter(difficulty)}
              </Typography>
              <Typography width={"100%"} textAlign="center">
                {`Win %: ${calculateWinPercentage(props.winRecords, difficulty, props.isTimed[difficulty])}`}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Radio
                    id={difficulty}
                    value={difficulty}
                    checked={difficulty === difficultySelected}
                    onChange={radioChangeEventHandler}
                    sx={{
                      color:"primary.main"
                    }}
                  />
                <Stack direction={"column"} spacing={1}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Avatar src={grid} variant="square" />
                    <Stack direction="column" justifyContent={"center"}>
                      <Typography>{`${props.difficultyParameters[difficulty].board.height}x${props.difficultyParameters[difficulty].board.width}`}</Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Avatar src={mine} />
                    <Stack direction="column" justifyContent={"center"}>
                      <Typography>
                        {props.difficultyParameters[difficulty].mineCount}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Avatar src={timeBomb} />
                    <Stack direction="column" justifyContent={"center"}>
                      <Typography width={40} textAlign="left">
                        {timeDisplayString(props.bestTime[difficulty])}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Checkbox
                        onChange={timedCheckboxChangeHandler}
                        checked={props.isTimed[difficulty]}
                        value={difficulty}
                        sx={{
                          color:"primary.main"
                        }}
                    />
                    <Stack direction="column" justifyContent={"center"}>
                      <Typography width={40} textAlign="left">
                        Timed
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {props.bestTime[difficulty] !== props.difficultyParameters[difficulty].defaultTime && props.isTimed[difficulty] &&
              (
                <Button
                  onClick={() =>
                    props.updateBestTime(
                      difficulty,
                      props.difficultyParameters[difficulty].defaultTime
                    )
                  }
                >
                  {`Reset? (Default: ${timeDisplayString(
                    props.difficultyParameters[difficulty].defaultTime
                  )})`}
                </Button>
              )}
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
}

export default Settings;
