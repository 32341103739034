import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Timesweeper from "./projects/timesweeper/src/TimeSweeper";
import Blog from "./pages/Blog";
import NotFound from "./pages/NotFound";

function App() {
  const [codeRain, setCodeRain] = useState(true);
  const [faceBlinking, setFaceBlinking] = useState(0);
  const [faceIndex, setFaceIndex] = useState(0);

  const toggleBlink = () => {
    setFaceBlinking((faceBlinking + 1) % 2);
  };

  const advanceFace = () => {
    setFaceIndex((faceIndex + 1) % 4);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/home"
        element={
          <Home
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/about"
        element={
          <About
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/about/professional-history"
        element={
          <About
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            open={"Professional History"}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/about/personal-history"
        element={
          <About
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            open={"Personal History"}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/about/things-i-like"
        element={
          <About
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            open={"Things I Like"}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/blog"
        element={
          <Blog
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/blog/articles-with-excel"
        element={
          <Blog
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
            open="Building an Article with Excel"
          />
        }
      />
      <Route
        path="/contact"
        element={
          <Contact
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/projects"
        element={
          <Projects
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="/projects/timesweeper"
        element={
          <Timesweeper
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
      <Route
        path="*"
        element={
          <NotFound
            faceBlinking={faceBlinking}
            faceIndex={faceIndex}
            toggleBlink={toggleBlink}
            advanceFace={advanceFace}
            codeRain={codeRain}
            setCodeRain={setCodeRain}
          />
        }
      />
    </Routes>
  );
}

export default App;
