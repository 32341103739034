import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material"
import NewTabLink from "../navigation/NewTabLink";
const ProjectCard = (props) => {

    let navButton = (<Typography color="warning.main">Coming Soon</Typography>);
    if (props.buttonType === "internal") {
        navButton = (<Button variant="contained" onClick={props.onClick}>
                        {props.buttonText}
                    </Button>)
    } else if (props.buttonType === "external") {
        navButton = (<NewTabLink href={props.href} text={props.buttonText} />)
    }
    return (
        <Box width="300px">
            <Card>
                <CardMedia component={"img"} />
                <CardContent>
                    <Typography gutterBottom variant={props.headerVariant || "h3"} component={"div"}>{props.header}</Typography>
                    <Typography variant={props.bodyVariant || "body2"} component={"div"}>{props.body}</Typography>
                </CardContent>
                <CardActions>
                    {navButton}
                </CardActions>
            </Card>
        </Box>
    )
}

export default ProjectCard