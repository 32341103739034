import { useNavigate } from "react-router";
import NavBar from "../components/navigation/NavBar";
import CodeRain from "../components/display/effects/CodeRain";
import CardContainer from "../components/display/CardContainer";

const Projects = (props) => {
  const navigate = useNavigate();
  const projectData = [
    {
      header: "TimeSweeper",
      body: "Minesweeper with a TIMED twist. One of the hidden mines is a timebomb! Flag it before it explodes or the game is over. Oh, your best time becomes the new timebomb clock, fyi...",
      bodyVariant: "body",
      buttonText: "Play TimeSweeper",
      buttonType: "internal",
      onClick: () => {
        navigate("timesweeper");
      },
    },
    {
      header: "Snake",
      body: "The age old game of 'Eat the Apple, grow the snake'... Do snakes eat apples? I don't know, but do your best to avoid hitting yourself!",
      bodyVariant: "body",
    },
    {
      header: "Qizurd",
      body: "A Manager needed a quick way to track the verbal quizzes he gives his employees. A frontend developer and I put together the 'Qizurd' website to meet that need. It is a full stack app, using React, an Express server, and MongoDB database.",
      bodyVariant: "body",
      buttonText: "Visit Qizurd",
      buttonType: "external",
      href: "https://qizurd-robsim37.vercel.app/",
    },
  ];
  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <CardContainer projectsData={projectData} />
    </>
  );
};

export default Projects;
