import { useState } from "react"
import Home from "./components/views/Home";
import GamePlay from "./components/views/GamePlay";
import { convertToMilliseconds } from "./utils/time";

function Timesweeper(props) {
  
  const [view, setView] = useState("home");
  const [difficulty, setDifficulty] = useState("easy");
  const [bestTime, setBestTime] = useState({hard: convertToMilliseconds(10, "minute"),
                                            intermediate: convertToMilliseconds(5, "minute"),
                                            easy: convertToMilliseconds(1, "minute")
                                          })
  const [isTimed, setIsTimed] = useState({hard: true,
                                          intermediate: true,
                                          easy: true
                                        })
  const [winRecords, setWinRecords] = useState({hard: {timed: [], untimed: []},
                                          intermediate: {timed: [], untimed: []},
                                          easy: {timed: [], untimed: []}
                                        })
  const [radar, setRadar] = useState(0);
  const [winCounter, setWinCounter] = useState(0);
  const [lossCounter, setLossCounter] = useState(0);
  const [addTime, setAddTime] = useState(0);
  const [clickCounter, setClickCounter] = useState(0);

  const updateBestTime = (difficulty, time) => {
    const newBestTime = {...bestTime};
    newBestTime[difficulty] = time;
    setBestTime(newBestTime);
  }
  const updateIsTimed = (difficulty, newIsTimed) => {
    const newIsTimedObj = {...isTimed};
    newIsTimedObj[difficulty] = newIsTimed;
    setIsTimed(newIsTimedObj);
  }
  const updateWinRecord = (difficulty, isWin, isTimed) => {
    const timedKey = isTimed ? "timed" : "untimed";
    const newRecordObj = {...winRecords};
    newRecordObj[difficulty][timedKey].push(isWin);
    setWinRecords(newRecordObj);
    if (isWin) {
      const newWinCount = (winCounter + 1) % 5
      setWinCounter(newWinCount);
      if (newWinCount === 0) {
        setRadar(()=>radar + 1);
      }
    } else {
      const newLossCount = (lossCounter + 1) % 10
      setLossCounter(newLossCount)
      if (newLossCount === 0) {
        setRadar(()=>radar + 1);
      }
    }
  }
  const updateClickCounter = (inc) => {
    const newClickCounter = (clickCounter + inc) % 100
    setClickCounter(newClickCounter)
    if (newClickCounter === 0) {
      setAddTime(()=>addTime + 1)
    }
  }
  const difficultyParameters = {
    hard: {board: {width: 30, height: 16}, mineCount: 99, defaultTime: convertToMilliseconds(10, "minute")},
    intermediate: {board: {width: 16, height: 16}, mineCount: 40, defaultTime: convertToMilliseconds(5, "minute")},
    easy: {board: {width: 10, height: 10}, mineCount: 10, defaultTime: convertToMilliseconds(1, "minute")}
  }
  switch (view) {
    case "home":
      return (<div className="timesweeper">
                <Home
                  setView={setView}
                  setDifficulty={setDifficulty}
                  difficulty={difficulty}
                  bestTime={bestTime}
                  updateBestTime={updateBestTime}
                  difficultyParameters={difficultyParameters}
                  isTimed={isTimed}
                  updateIsTimed={updateIsTimed}
                  winRecords={winRecords}
                  faceBlinking={props.faceBlinking}
            faceIndex={props.faceIndex}
            toggleBlink={props.toggleBlink}
            advanceFace={props.advanceFace}
            codeRain={props.codeRain}
            setCodeRain={props.setCodeRain}
                />
              </div>)
    case "gamePlay":
      return (<div className="timesweeper" onContextMenu={e=>e.preventDefault()}>
                <GamePlay
                  setView={setView}
                  updateBestTime={updateBestTime}
                  difficulty={difficulty}
                  bestTime={bestTime}
                  boardWidth={difficultyParameters[difficulty].board.width}
                  boardHeight={difficultyParameters[difficulty].board.height}
                  mineCount={difficultyParameters[difficulty].mineCount}
                  isTimed={isTimed}
                  winRecords={winRecords}
                  updateWinRecord={updateWinRecord}
                  radar={radar}
                  setRadar={setRadar}
                  addTime={addTime}
                  setAddTime={setAddTime}
                  clickCounter={clickCounter}
                  updateClickCounter={updateClickCounter}
                />
              </div>)
    default:
      return <div className="timesweeper"></div>
  }
}

export default Timesweeper;