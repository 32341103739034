import { Typography } from "@mui/material"

const FadingCharacter = (props) => {
    return (
        <Typography
            variant="h4"
            color={props.opacity === 1 ? "text" : "secondary.main"}
            sx={{opacity:(props.opacity)}}
        >
            {props.char}
        </Typography>
    )
}

export default FadingCharacter