import NavBar from "../components/navigation/NavBar";
import CodeRain from "../components/display/effects/CodeRain";
import FilteredContent from "../components/controls/filter/FilteredContent";
import NewTabLink from "../components/navigation/NewTabLink"
import { Box } from "@mui/material";
const Blog = (props) => {
  const filterBuilders = [
    {
      label: "Filter by Title:",
      color: "secondary",
      key: "title",
    },
    {
      label: "Filter by Content:",
      color: "primary",
      key: "body",
    },
    {
      label: "Filter by Tags:",
      color: "secondary",
      key: "tags",
    },
  ];

  const articles = [
    {
      postedDate: "2023-4-16",
      title: "Building an Article with Excel",
      subtitle: "Who doesn't love Excel?",
      innerHeader: "R. Lewis - 2023-4-16",
      tags: ["excel", "processes", "article"],
      body: [
        "Excel is great. While the basic calculation operations of the spreadsheet program are impressive enough, it is also a fantastic programming platform. While the language that it uses, Visual Basic for Applications (VBA) may not be the most robust, it certainly is quick, flexible, and provides built-in data objects with its Workbooks and Worksheets. I have personally used Excel to do things far beyond its intended use. This includes a Sudoku solver, Minesweeper, and a rudimentary version of Tetris. For the purposes of this article, however, I want to talk about how I use it to help build the blog articles for this website.",
        "First, things first, it must be said that Excel isn’t the right tool for every job. I don’t use it to write the content of the article. It isn’t the best at writing large blocks of text. Word is the better tool for that job, with better spell check and word wrap features. However, turning this text into a format that is displayed on the webpage, with its title, subtitle, tags, and posted date, is something that Excel can do quite well.",
        "Once the body of the article has been completed, I copy and past that content into an Excel spreadsheet where I add the other required data. Then I run the VBA code to convert that data into a valid JSON object that can be uploaded to the sites no-SQL database.",
        "This workflow makes posting articles easy and straightforward. Just like everything Excel does!",
      ],
    },
    {
      postedDate: "2023-4-16",
      title: "The Captain is Dead",
      subtitle: "The Dream Team (for 5 players)",
      innerHeader: "R. Lewis - 2023-4-16",
      tags: ["Board Games", "Fun"],
      body: [
        "'The Captain is Dead' is a delightful cooperative game, themed as a sci-fi adventure akin to Star Trek (although I can't recall any episodes of that show where the captain dies… permanently). Like many other co-op games, it shares a lot of the mechanics common to the genre. Your team will consist of varied roles, there is a strong emphasis on team planning, resource trading, multiple ways of losing, and a variable starting difficulty that allows replay ability with greater and greater challenges.",
        <Box
          component="img"
          alt="The Captain is Dead"
          src="https://cf.geekdo-images.com/6BBJr3Ch9NDKJIyzqKrhOg__imagepage/img/NBVgzZjhe026SEwtiUhfo8emrVU=/fit-in/900x600/filters:no_upscale():strip_icc()/pic3279087.jpg"
        />,
        <NewTabLink text="The Captain is Dead - Board Game Geek" href="https://boardgamegeek.com/boardgame/159503/captain-dead" />,
        "Having recently been introduced to the game, my son and I theory crafted a fantastic 5 player team that (nearly) beat the game at its hardest difficulty. This was only our third time playing, so we were excited to have gotten so close. With the increased familiarity we gained with that playthrough, we are confident we could beat the game on our next try.",
        "Here is the team we selected with a rationale for why:",
        "The Admiral - One of the actions in the game is to review a “Battle Plan”. These are “always good” cards that provide various benefits to the crew. The Admiral has the special ability to draw two and choose one, seeing twice as many cards as any other role might. This is very efficient when digging for some key cards that aid in victory. Given that the unused cards are eventually shuffled back into the draw pile, there really is no downside.",
        "The Soldier - One of the ways to lose the game is to be overrun by alien intruders. The Soldier is the simple and powerful solution to that problem. Where other roles must spend an action for each intruder they want to dispatch, the S.O. clears all aliens in a room for one action. This is a fantastic way to keep the ship clear and the rest of the team focused on their tasks. This isn't a flashy or flexible role, but it is a key component to a winning strategy.",
        "The Scholar - While an argument could be made that the Science Officer should take this slot on the team, with its higher science abilities out of the box, when playing the Admiral, the Scholar is the right choice. First, the compensation for a lower science score is its ability to draw a free skill card whenever they have none. Giving their skill cards away to other players is a fantastic way to increase the whole team's skill card inventory. Second, with the Admiral digging through the battleplans, it won't be long before the science upgrade card will be found. This negates any deficiencies the Scholar has.",
        "The Engineer - While there are multiple ways to lose the game, there is only one way to win it. You must get the engines back online. This is the role that gets the job done. Like the Soldier, this role isn't the most flexible, but it is critical to winning the game before time runs out and all is lost. There will certainly be other systems that go offline, and the Engineer can handle that, but the key to winning will be focusing on those engines.",
        "The Janitor - Don't laugh. No serious, the Janitor is a key member of the team. This role is the most flexible in the game. It starts with a high number of actions per turn and can trade those actions for skill points. This flexibility can be used to shore up the shields in the early game. In the late game, they can aid in fixing the engines. They may just be the hero of the game… like Rodger Wilco!",
        "While I certainly recommend this team for your next session, the game is fun no matter how you play it!",
      ],
    },
  ];

  return (
    <>
      <NavBar
        faceBlinking={props.faceBlinking}
        faceIndex={props.faceIndex}
        toggleBlink={props.toggleBlink}
        advanceFace={props.advanceFace}
        codeRain={props.codeRain}
        setCodeRain={props.setCodeRain}
      />
      <CodeRain codeRain={props.codeRain} />
      <FilteredContent
        open={props.open}
        filters={props.filters}
        filterBuilders={filterBuilders}
        articles={articles}
      />
    </>
  );
};

export default Blog;
