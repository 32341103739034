import {Box} from "@mui/material"

const ImageCircle = (props) => {
    return (
        <Box
            variant="rounded"
            sx={{
              height: props.outerRadius || 320,
              width: props.outerRadius || 320,
              borderRadius: 100,
              border: props.outerBorder || 2,
              borderColor: "primary.main",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              background:"black"
            }}
          >
            <Box
              component="img"
              variant="rounded"
              sx={{
                height: props.innerRadius || 300,
                width: props.innerRadius || 300,
                borderRadius: 100,
                border: props.innerBorder || 2,
              borderColor: "primary.main",
              }}
              alt="portrat of Rob Lewis"
              src={props.src}
            />
          </Box>
    )
}

export default ImageCircle