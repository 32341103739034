import { Chip, Typography } from "@mui/material"

const FilterChip = (props) => {

    const chipDeleteClickHandler = () => {
        props.onDelete(props.label)
    }
    return (
        <Chip label={<Typography color="white">{props.label}</Typography>} color={props.color}  onDelete={props.onDelete ? chipDeleteClickHandler : null}></Chip>
    )
}

export default FilterChip