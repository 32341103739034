import { useEffect, useState, useMemo } from "react";
import useStopWatch from "../../../../../hooks/useStopWatch";
import {
  initBoard,
  gridToDisplay,
  updateBoard,
  autoReveal,
  isWin,
  isLoss,
  showAll,
  flagAll,
  detectMine,
  winsAndLosses,
} from "../../utils/gamePlay";
import { convertToMilliseconds, timeDisplayString } from "../../utils/time";
import { calculateWinPercentage } from "../../utils/gamePlay";
import Board from "../board/Board";
import {
  Typography,
  Button,
  Avatar,
  Card,
  Badge,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import mine from "../../resources/display/mine.png";
import timeBomb from "../../resources/display/timeBomb.png";
import RadarIcon from "@mui/icons-material/Radar";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
function GamePlay(props) {
  const [gameOver, setGameOver] = useState(false);
  const [isWon, setIsWon] = useState(false);
  const [board, setBoard] = useState(
    initBoard(props.boardWidth, props.boardHeight)
  );
  const [isFirstClick, setIsFirstClick] = useState(true);
  const [mineCount, setMineCount] = useState(props.mineCount);
  const [timerColor, setTimerColor] = useState("success.main");

  const generatedGrid = useMemo(()=>gridToDisplay(board),[board])
  const countUpTimer = useStopWatch({
    startingTime: 0,
    delta: props.isTimed ? 10 : 0,
  });
  const countDownTimer = useStopWatch({
    startingTime: props.bestTime[props.difficulty],
    delta: props.isTimed ? -10 : 0,
  });
  const addTimeToolTipString = countDownTimer.running ? props.addTime === 0 ? 
  `${100-props.clickCounter} Clicks earns an Add Time Bonus` :
  `Add ${props.addTime * 5} seconds` :
  `Timer must be running to earn the Add Time Bonus (${100-props.clickCounter} clicks)`
  useEffect(() => {
    if (countDownTimer.time <= 0 && !gameOver) {
      let updatedBoard = board;
      updatedBoard = showAll(updatedBoard);
      setGameOver(true);
      setTimerColor("error");
      countUpTimer.setRunning(false);
      countDownTimer.setRunning(false);
      props.updateWinRecord(props.difficulty, false, props.isTimed);
      setBoard([...updatedBoard]);
    }
  }, [
    countDownTimer.time,
    board,
    countUpTimer,
    countDownTimer,
    gameOver,
    props,
  ]);

  const resetGame = () => {
    countDownTimer.setTime(props.bestTime[props.difficulty]);
    countUpTimer.setTime(0);
    setBoard(() => initBoard(props.boardWidth, props.boardHeight));
    setTimerColor("success.main");
    setIsFirstClick(true);
    setMineCount(props.mineCount);
    setGameOver(false);
    setIsWon(false);
  };
  const constHomeButtonClickEventHandler = () => {
    countUpTimer.setRunning(false);
    countDownTimer.setRunning(false);
    if (!isFirstClick) {
        props.updateWinRecord(
        props.difficulty,
        false,
        props.isTimed[props.difficulty]
      );
    }
    props.setView("home");
  };
  const radarClickHandler = (e) => {
    if (props.radar < 1 || gameOver || isFirstClick) return;
    const updatedBoard = detectMine(board, props.radar);
    let markedCells = 0;
    updatedBoard.forEach(row=>row.forEach(
        cell=>{if (cell.marked) markedCells++}
    ))
    setMineCount(props.mineCount - markedCells)
    setBoard([...updatedBoard]);
    props.setRadar(props.radar - 1);
  };

  const addTimeClickHandler = (e) => {
    if (props.addTime < 1 || gameOver || isFirstClick || !props.isTimed[props.difficulty] || !countDownTimer.running) return;
    countDownTimer.setTime(countDownTimer.time + convertToMilliseconds(5 * props.addTime, "second"));
    props.setAddTime(props.addTime - 1)
  }
  const cellFunctions = {
    cellClicked: (x, y) => {
      if (board[y][x].marked || gameOver) return;
      let updatedBoard = board;
      if (board[y][x].hidden) {
        if (isFirstClick) {
          if (props.isTimed[props.difficulty]) {
            countUpTimer.setRunning(true);
            countDownTimer.setRunning(true);
          }
          setTimerColor("error");
          updatedBoard = initBoard(
            props.boardWidth,
            props.boardHeight,
            { x, y },
            props.mineCount,
            props.isTimed[props.difficulty]
          );
          setIsFirstClick(false);
        }
        updatedBoard = updateBoard(updatedBoard, x, y);
      } else {
        updatedBoard = autoReveal(updatedBoard, x, y);
      }
      if (isLoss(updatedBoard)) {
        updatedBoard = showAll(updatedBoard);
        setGameOver(true);
        setIsWon(false);
        countUpTimer.setRunning(false);
        countDownTimer.setRunning(false);
        props.updateWinRecord(
          props.difficulty,
          false,
          props.isTimed[props.difficulty]
        );
        setTimerColor("error");
      } else if (isWin(updatedBoard)) {
        updatedBoard = flagAll(updatedBoard);
        setMineCount(0);
        setTimerColor("success.main");
        setGameOver(true);
        setIsWon(true);
        countUpTimer.setRunning(false);
        countDownTimer.setRunning(false);
        props.updateWinRecord(
          props.difficulty,
          true,
          props.isTimed[props.difficulty]
        );
        if (
          countUpTimer.time < props.bestTime[props.difficulty] &&
          props.isTimed[props.difficulty]
        ) {
          props.updateBestTime(props.difficulty, countUpTimer.time);
        }
      }
      if (countDownTimer.running) {
        props.updateClickCounter(1)
      }
      setBoard([...updatedBoard]);
    },
    cellRightClicked: (x, y) => {
      if (!board[y][x].hidden || gameOver || isFirstClick) return;
      let updatedBoard = board;
      if (updatedBoard[y][x].marked) {
        setMineCount((count) => count + 1);
      } else {
        if (updatedBoard[y][x].isTimebomb) {
          countDownTimer.setRunning(false);
          setTimerColor("success.main");
        }
        setMineCount((count) => count - 1);
      }
      updatedBoard[y][x].marked = !updatedBoard[y][x].marked;
      setBoard([...updatedBoard]);
    },
  };
  
  return (<Stack display="flex" justifyContent={"center"} alignItems="center">
    <Stack direction="row" spacing={1} mt={1}>
      <Stack direction="column" spacing={1} mt={12}>
        <Tooltip title={props.radar === 0 ? 
            "Win 5 or lose 10 for a Radar Sweep" :
            `Each normal mine has a ${props.radar * 15}% chance of being marked`}
            enterDelay={500}
        >
          <Button onClick={radarClickHandler}>
            <Badge
              badgeContent={props.radar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              color="primary"
              overlap="circular"
            >
              <Avatar sx={{ color: "white", background: "black" }}>
                <RadarIcon sx={{ color: "white", background: "black" }}/>
              </Avatar>
            </Badge>
          </Button>
        </Tooltip>
        {props.isTimed[props.difficulty] &&
        <Tooltip title={addTimeToolTipString}
            enterDelay={500}
        >
          <Button onClick={addTimeClickHandler}>
            <Badge
              badgeContent={props.addTime}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              color="primary"
              overlap="circular"
            >
              <Avatar sx={{ color: "white", background: "black" }}>
                <AccessTimeIcon sx={{ color: "white", background: "black" }}/>
              </Avatar>
            </Badge>
          </Button>
        </Tooltip>}
      </Stack>
      <Stack direction="column" spacing={1} mt={1}>
        <Stack direction="row" justifyContent={"center"}>
          <Card elevation={10} sx={{ display:"flex", justifyContent: "center", alignItems: "center" }}>
            <Button variant={gameOver ? "contained" : "outlined"} onClick={constHomeButtonClickEventHandler}>Menu</Button>
          </Card>
        </Stack>
        <Card elevation={10} sx={{border:2, borderColor:"primary.main"}}>
          <Stack direction="row" justifyContent="space-evenly">
            <Stack direction="row" spacing={1} minWidth={100}>
              <Avatar src={mine}></Avatar>
              <Stack
                direction="column"
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Typography>{mineCount}</Typography>
              </Stack>
            </Stack>
            {props.isTimed[props.difficulty] && (
              <Stack direction="row" spacing={1} minWidth={100}>
                <Avatar src={timeBomb}></Avatar>
                <Stack
                  direction="column"
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  <Typography color={timerColor}>
                    {timeDisplayString(Math.max(countDownTimer.time, 0))}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Card>
        <Card elevation={10}>
          <Board grid={generatedGrid} cellFunctions={cellFunctions} />
        </Card>
        {gameOver && (
          <Stack direction={"column"} mt={1} spacing={1} alignItems="center">
            <Card elevation={10} sx={{ width: "100%", border:2, borderColor:"primary.main" }}>
              <Typography textAlign="center" sx={{ width: "100%" }}>
                {isWon ? `You Won!` : `You Lose!`}
              </Typography>
              <Typography textAlign="center">
                {`${winsAndLosses(
                  props.winRecords,
                  props.difficulty,
                  props.isTimed[props.difficulty]
                )} - ${calculateWinPercentage(
                  props.winRecords,
                  props.difficulty,
                  props.isTimed[props.difficulty]
                )}`}
              </Typography>
              {isWon && props.isTimed[props.difficulty] && (
                <Stack direction={"column"}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Typography>Time:</Typography>
                    <Typography>
                      {timeDisplayString(countUpTimer.time)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"center"}
                  >
                    <Typography>Best Time:</Typography>
                    <Typography>
                      {timeDisplayString(props.bestTime[props.difficulty])}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              {isWon &&
                countUpTimer.time === props.bestTime[props.difficulty] &&
                props.isTimed[props.difficulty] && (
                  <Typography textAlign="center">A new record!</Typography>
                )}
            </Card>
            <Card elevation={10} sx={{ display:"flex", justifyContent: "center", alignItems: "center" }}>
              <Button variant="contained" onClick={resetGame}>Replay?</Button>
            </Card>
          </Stack>
        )}
      </Stack>
    </Stack></Stack>
  );
}

export default GamePlay;
