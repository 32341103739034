import {
    IconButton
  } from "@mui/material";
  import WaterDropIcon from '@mui/icons-material/WaterDrop';
  import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';

const RainToggleButton = (props) => {
    return (
        <IconButton onClick={()=>props.onClick(()=>!props.codeRain)}>
            {props.codeRain ? <WaterDropIcon color="primary"/> : <WaterDropOutlinedIcon color="primary"/>}
        </IconButton>
    )
}

export default RainToggleButton