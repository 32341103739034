import { Paper, Stack, TextField, Typography } from "@mui/material";
import ChipsContainer from "./ChipsContainer";

const FilterBuilder = (props) => {
    const {filter} = props;
    return (
        <Paper>
            <Stack direction="row" p={1}>
                <TextField
                    variant="outlined"
                    label={<Typography color="text.primary">{filter.label}</Typography>}
                    color={filter.color}
                    onChange={filter.onChange}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter')
                            {
                                props.addFilter(filter.key, e.target.value);
                                e.target.value="";
                            }
                    }}
                />
                <ChipsContainer
                    chipValues={props.filters.filter(filter=>filter.key === props.filter.key).map(filter=>filter.value)}
                    color={filter.color}
                    onDelete={(value)=>{
                        props.deleteFilter(filter.key, value)
                    }}
                />
            </Stack>
        </Paper>
    )
}

export default FilterBuilder