import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material"
import FilterBuilder from "./FilterBuilder"
import { useState } from "react"
import AccordionGroup from "../../display/AccordionGroup";
import ClearFilters from "./ClearFilters";

const FilteredContent = (props) => {

    const [filters, setFilters] = useState(props.filters || []);

    const filterArticles = (articles) => {
        if (articles.length === 0) return null;
        let filteredArticles = articles;
        filters.forEach(filter=>
                {
                    filteredArticles = filteredArticles.filter(article=>{
                        if (!article[filter.key]) return false;
                        if (typeof article[filter.key] === "string") {
                            return article[filter.key].toLowerCase().includes(filter.value.toLowerCase())
                        } else if (Array.isArray(article[filter.key])) {
                            let filterPassed = false;
                            article[filter.key].forEach(paragraph=>{
                                if (typeof paragraph === "string") {
                                    filterPassed = filterPassed || paragraph.toLowerCase().includes(filter.value.toLowerCase())
                                }
                            })
                            return filterPassed
                        } else {
                            return true;
                        }
                    })
                }
            )
        return filteredArticles;
    }

    const addFilter = (key, value) => {
        if (!filters.filter(filter=>filter.key === key).map(filter=>filter.value).includes(value)) {
            setFilters(()=>[...filters, {key, value}]);
        }
    }

    const deleteFilter = (key, value) => {
        setFilters(()=>[...filters].filter(filter=>filter.key !== key || filter.value !== value));
    }

    const clearFilters = () => {
        setFilters([]);
    }

    const filteredArticles = filterArticles(props.articles);

    return (
        <Stack>
            <Accordion>
                <AccordionSummary>Filters</AccordionSummary>
                <AccordionDetails>
                    {props.filterBuilders.map(filter=>{return(
                    <FilterBuilder
                        key={Math.random()}
                        filter={filter}
                        filters={filters}
                        addFilter={addFilter}
                        deleteFilter={deleteFilter}
                    />
                    )})}
                </AccordionDetails>
            </Accordion>
            
            {filteredArticles.length > 0 ? 
            <AccordionGroup open={props.open}>
                {filteredArticles}
            </AccordionGroup>
            :
            <ClearFilters onClick={clearFilters} />
            }
        </Stack>
    )
}

export default FilteredContent