import { Stack, Button } from "@mui/material";

const ButtonArray = (props) => {
  return (
    <Stack direction={"row"} spacing={2}>
      {props.buttonSettings.map((set) => {
        return (
          <Button key={set.page} variant={set.type} onClick={set.onClick}>
            {set.page}
          </Button>
        );
      })}
    </Stack>
  );
};

export default ButtonArray;
