import { Stack, Typography, Button, Paper } from "@mui/material";

const ClearFilters = (props) => {

    return (
        <Paper>
            <Stack spacing={3} direction="row" alignItems={"center"} justifyContent={"center"}>
                <Typography>No Matching Articles</Typography>
                <Button onClick={props.onClick}>
                    Clear Filters
                </Button>
            </Stack>
        </Paper>
    )
}

export default ClearFilters