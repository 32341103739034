import AccordionGroup from "../../display/AccordionGroup";
import NewTabLink from "../../navigation/NewTabLink";

const BandAccordion = () => {
  const bandObjs = [
    {
      title: "Queen",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=kijpcUv-b8M"
          text="Somebody To Love"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=HgzGwKwLmgM"
          text="Don't Stop Me Now"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=f4Mc-NYPHaQ"
          text="I Want To Break Free"
        />,
      ],
    },
    {
      title: "Weezer",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=jwv-iRvyDZg"
          text="Perfect Situation"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=0LSGvziX_6Y"
          text="Troublemaker"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=HL_WvOly7mY"
          text="Beverly Hills"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=aQbXhdJYqhE"
          text="Dope Nose"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=hOIsYA1QDuk"
          text="Keep Fishin' - feat. THE MUPPETS"
        />,
      ],
    },
    {
      title: "Fun.",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=dO6WmM7w93I"
          text="Why Am I The One"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=n0pNVHiNxqk"
          text="Benson Hedges"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=AIoaiTwLk6I"
          text="All The Pretty Girls"
        />,
      ],
    },
    {
      title: "Green Day",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=r00ikilDxW4"
          text="21 Guns"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=A1OqtIqzScI"
          text="Holiday"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=i8dh9gDzmz8"
          text="When I Come Around"
        />,
      ],
    },
    {
      title: "Cake",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=bsL1wgy1j-Q"
          text="Shadow Stabbing"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=FRZzUh9hcTo"
          text="Never There"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=5rk3mxeviXA"
          text="Dime"
        />,
      ],
    },
    {
      title: "They Might Be Giants",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=t5BNDI5IExs"
          text="Ana Ng"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=PagUXMTQUUs"
          text="Bangs"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=33BTvjZ45MI"
          text="Women & Men"
        />,
      ],
    },
    {
      title: "'Weird Al' Yankovic",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=N9qYF9DZPdw"
          text="White & Nerdy"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=DFI6cV9slfI"
          text="Hardware Store"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=oNEdEDbhTQw"
          text="The Hamilton Polka"
        />,
      ],
    },
    {
      title: "Pentatonix",
      body: [
        <NewTabLink
          href="https://www.youtube.com/watch?v=Yc7-krRX8uA"
          text="Sing"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=P95_pCbCPZw"
          text="Cheerleader"
        />,
        <NewTabLink
          href="https://www.youtube.com/watch?v=3MteSlpxCpo"
          text="Daft Punk"
        />,
      ],
    },
  ];
  return <AccordionGroup>{bandObjs}</AccordionGroup>;
};

export default BandAccordion;
